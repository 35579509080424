<template>
    <div class="error404">error 404</div>
</template>

<script>
    export default {
        name: 'Error404'
    };
</script>

<style></style>
